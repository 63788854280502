var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "EventMeets container" }, [
    _c(
      "div",
      { staticClass: "EventMeets-program card" },
      [
        _c("DatePicker", {
          attrs: { mode: "dateTime", value: "date" },
          model: {
            value: _vm.date,
            callback: function($$v) {
              _vm.date = $$v
            },
            expression: "date"
          }
        }),
        _c(
          "div",
          { staticClass: "fields-container" },
          [
            _c(
              "BField",
              { attrs: { label: "Title", "label-position": "on-border" } },
              [
                _c("BInput", {
                  model: {
                    value: _vm.title,
                    callback: function($$v) {
                      _vm.title = $$v
                    },
                    expression: "title"
                  }
                })
              ],
              1
            ),
            _c(
              "BField",
              { attrs: { label: "Attendees", "label-position": "on-border" } },
              [
                _c("BTaginput", {
                  attrs: {
                    value: _vm.attendees,
                    ellipsis: "",
                    icon: "envelope-square",
                    placeholder: "Add a email",
                    type: "is-info"
                  },
                  model: {
                    value: _vm.attendees,
                    callback: function($$v) {
                      _vm.attendees = $$v
                    },
                    expression: "attendees"
                  }
                })
              ],
              1
            ),
            _c(
              "BButton",
              {
                staticClass: "is-primary",
                attrs: { expanded: "" },
                on: {
                  click: function($event) {
                    return _vm.loginGapi()
                  }
                }
              },
              [_vm._v("Program event")]
            ),
            _c("br"),
            _c("p", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.error))
            ])
          ],
          1
        ),
        _c("LoadingOverlay", { attrs: { loading: _vm.loading } })
      ],
      1
    ),
    _c("img", { attrs: { src: require("../../assets/calendar.svg") } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }