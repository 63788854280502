import { functions } from '@/initFirebase';
function sendVerificationMail(notification) {
    notification.open({
        message: 'Sending verification email...',
        position: 'is-top-right'
    });
    functions
        .httpsCallable('sendVerificationEmail')(window.location.href)
        .then(function () {
        notification.open({
            message: 'Successfully sent verification email!',
            type: 'is-success',
            position: 'is-top-right'
        });
    })
        .catch(function (err) {
        notification.open({
            message: 'Could not send the verification email',
            type: 'is-danger',
            position: 'is-top-right'
        });
        console.log(err);
    });
}
export { sendVerificationMail };
