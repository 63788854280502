<template lang="pug">
.container
  .user-stories-header
    .title.is-4.has-text-info My BIMMeets
    BButton.createmeet-btn(
      icon-right='video',
      type='is-info',
      size='is-medium',
      @click='$parent.createNewMeet'
    ) Start a !{' '}
      span.has-text-primary.has-text-weight-medium BIM
      span.has-text-weight-bold Meet

  .columns.is-multiline.story-list
    .column.is-half(v-for='meet in meets', :key='meet.id')
      UserMeetCard(:meet='meet')

    .column.has-text-centered(v-if='!meets.length')
      p.subtitle.has-text-info You have no meets
</template>

<script>
import Vue from 'vue'
import UserMeetCard from '@/components/UserProfile/UserMeetCard.vue'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'UserStories',
  components: { UserMeetCard },
  props: ['loading'],
  computed: {
    meets() {
      return store.state.user.meets || []
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .user-stories-header {
    position: relative;
    @include flex(flex-start);
    margin-bottom: 40px;
    .title {
      margin-bottom: 0 !important;
    }
    .createmeet-btn {
      @include posAbsolute();
    }
  }
}
</style>
