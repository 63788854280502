var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-profile is-fullheight-with-navbar",
      class: { "block-scroll": _vm.creatingMeet }
    },
    [
      _c("UserDetails", { attrs: { userData: _vm.userData } }),
      _c(
        "div",
        { staticClass: "tabs-container" },
        [
          _c(
            "BTabs",
            {
              attrs: { size: "is-medium" },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c("BTabItem", { attrs: { label: "Meets", icon: "video" } }),
              _c("BTabItem", {
                attrs: { label: "Program meet", icon: "calendar-alt" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section user-stories" },
        [
          _vm.activeTab === 0 && _vm.emailVerified
            ? _c("UserStories", { attrs: { loading: _vm.loading } })
            : _vm._e(),
          _vm.activeTab === 1 && _vm.emailVerified
            ? _c("EventMeets")
            : _vm._e(),
          !_vm.emailVerified ? _c("VerifyEmailTemplate") : _vm._e()
        ],
        1
      ),
      _c("LoadingMeetModal", { attrs: { creatingMeet: _vm.creatingMeet } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }