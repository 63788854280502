import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import getCalendarBody from './gapiHelpers';
import LoadingOverlay from '@/components/common/LoadingOverlay.vue';
var EventMeets = (function (_super) {
    __extends(EventMeets, _super);
    function EventMeets() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.events = [];
        _this.date = new Date();
        _this.title = '';
        _this.attendees = [];
        _this.eventUrl = '';
        _this.loading = false;
        _this.description = '';
        _this.error = '';
        return _this;
    }
    EventMeets.prototype.loginGapi = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, !this.$gapi.isAuthenticated() ? this.$gapi.login() : this.createCalendarEvent()];
            });
        });
    };
    EventMeets.prototype.createCalendarEvent = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var meetId, _c, dateToIso, title, attendeesReq, description, client, event, eventBody, result_1, err_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.loading = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 6, , 7]);
                        return [4, this.$functions.httpsCallable('createMeeting')()];
                    case 2:
                        meetId = (_d.sent()).data;
                        return [4, this.$firestore.doc("meets/" + meetId).update({ 'guests': this.attendees })];
                    case 3:
                        _d.sent();
                        _c = this, dateToIso = _c.dateToIso, title = _c.title, attendeesReq = _c.attendeesReq, description = _c.description;
                        console.log('createCalendarEvent -> dateToIso', dateToIso);
                        this.description = window.location.origin + ("/meet/" + meetId);
                        return [4, this.$gapi.getGapiClient()];
                    case 4:
                        client = (_d.sent()).client;
                        event = client.calendar.events;
                        eventBody = getCalendarBody(dateToIso, title, description, attendeesReq);
                        console.log('createCalendarEvent -> eventBody', eventBody);
                        return [4, event.insert({
                                calendarId: 'primary',
                                'resource': eventBody
                            })];
                    case 5:
                        result_1 = (_d.sent()).result;
                        this.$log('success', 'event created', result_1);
                        setTimeout(function () {
                            _this.loading = false;
                            _this.promptEventUrl(result_1.htmlLink);
                        }, 600);
                        return [3, 7];
                    case 6:
                        err_1 = _d.sent();
                        console.log('createCalendarEvent -> result', err_1);
                        this.error = ((_b = (_a = err_1.result) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message) || '';
                        this.loading = false;
                        this.$emit('show-error', err_1.message);
                        setTimeout(function () { return (_this.error = ''); }, 5000);
                        return [3, 7];
                    case 7: return [2];
                }
            });
        });
    };
    EventMeets.prototype.getClientEvents = function () {
        return __awaiter(this, void 0, void 0, function () {
            var client;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.$gapi.getGapiClient()];
                    case 1:
                        client = (_a.sent()).client;
                        client.calendar.events
                            .list({
                            'calendarId': 'primary',
                            'timeMin': new Date().toISOString(),
                            'showDeleted': false,
                            'singleEvents': true,
                            'maxResults': 10,
                            'orderBy': 'startTime'
                        })
                            .then(function (_a) {
                            var result = _a.result;
                            console.log('result', result);
                            console.log('date', new Date().toISOString());
                            var events = result.items;
                            _this.events.push('upcoming events:');
                            if (events.length > 0) {
                                for (var i = 0; i < events.length; i++) {
                                    var event = events[i];
                                    var when = event.start.dateTime;
                                    if (!when) {
                                        when = event.start.date;
                                    }
                                    _this.events.push(event.summary + ' (' + when + ')');
                                }
                            }
                            else {
                                _this.events.push('No upcoming events found.');
                            }
                        });
                        return [2];
                }
            });
        });
    };
    EventMeets.prototype.promptEventUrl = function (url) {
        this.$buefy.dialog.alert({
            message: "\n        <div class=\"prompt-msg-container\">\n          <p class=\"subtitle\">Programmed event!</p> <br>\n          <a class=\"title is-4 has-text-primary\" href=" + url + " style=\"text-decoration: underline; \" target=\"_blank\"><b>Event link</b></a>\n        </div>\n        "
        });
    };
    Object.defineProperty(EventMeets.prototype, "dateToIso", {
        get: function () {
            return this.date.toISOString();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeets.prototype, "attendeesReq", {
        get: function () {
            return this.attendees.map(function (attendee) {
                return {
                    email: attendee
                };
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeets.prototype, "attendeesBot", {
        get: function () {
            return this.attendees.map(function (a) {
                return {
                    'keyValue': {
                        'topLabel': 'Invited',
                        'content': a
                    }
                };
            }, {});
        },
        enumerable: false,
        configurable: true
    });
    EventMeets = __decorate([
        Component({ components: { LoadingOverlay: LoadingOverlay } })
    ], EventMeets);
    return EventMeets;
}(Vue));
export default EventMeets;
