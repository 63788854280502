import { __decorate, __extends } from "tslib";
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
var UserMeetCard = (function (_super) {
    __extends(UserMeetCard, _super);
    function UserMeetCard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.Meet = null;
        _this.loading = true;
        _this.meetThumbnail = null;
        return _this;
    }
    UserMeetCard.prototype.processDate = function () {
        return moment(this.meet.created).toLocaleString();
    };
    UserMeetCard.prototype.mounted = function () {
        var _this = this;
        this.loading = true;
        this.fetchMeet().then(function (meet) {
            _this.Meet = meet;
            _this.loading = false;
            _this.fetchMeetThumbnail();
        });
    };
    UserMeetCard.prototype.fetchMeetThumbnail = function () {
        var _this = this;
        if (this.meet || this.Meet.urn) {
            var meetThumbnail = this.$functions.httpsCallable('meetThumbnail');
            meetThumbnail({
                urn: this.Meet.modelURN,
                options: { height: 100, width: 100 }
            })
                .then(function (res) {
                if (res.data)
                    _this.meetThumbnail = 'data:image/jpeg;base64,' + res.data;
            })
                .catch(function (err) { return console.log(err); });
        }
    };
    UserMeetCard.prototype.fetchMeet = function () {
        return this.$firestore
            .collection('meets')
            .doc(this.meet.id)
            .get()
            .then(function (doc) {
            return doc.data();
        })
            .catch(function (err) {
            console.error('could not load meet');
            throw err;
        });
    };
    UserMeetCard.prototype.goToMeet = function () {
        this.$router.push("/meet/" + this.meet.id);
    };
    __decorate([
        Prop()
    ], UserMeetCard.prototype, "meet", void 0);
    UserMeetCard = __decorate([
        Component
    ], UserMeetCard);
    return UserMeetCard;
}(Vue));
export default UserMeetCard;
