var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container has-text-centered" }, [
      _c("div", { staticClass: "columns is-vcentered" }, [
        _c("div", { staticClass: "column" }, [
          _c("p", { staticClass: "subtitle has-text-gray" }, [
            _vm._v("Please verify your email to be able to create new "),
            _c(
              "span",
              { staticClass: "has-text-primary has-text-weight-medium" },
              [_vm._v("BIM")]
            ),
            _c("span", { staticClass: "has-text-info has-text-weight-bold" }, [
              _vm._v("Meet")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }