var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "user-stories-header" },
      [
        _c("div", { staticClass: "title is-4 has-text-info" }, [
          _vm._v("My BIMMeets")
        ]),
        _c(
          "BButton",
          {
            staticClass: "createmeet-btn",
            attrs: {
              "icon-right": "video",
              type: "is-info",
              size: "is-medium"
            },
            on: { click: _vm.$parent.createNewMeet }
          },
          [
            _vm._v("Start a "),
            _c(
              "span",
              { staticClass: "has-text-primary has-text-weight-medium" },
              [_vm._v("BIM")]
            ),
            _c("span", { staticClass: "has-text-weight-bold" }, [
              _vm._v("Meet")
            ])
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "columns is-multiline story-list" },
      [
        _vm._l(_vm.meets, function(meet) {
          return _c(
            "div",
            { key: meet.id, staticClass: "column is-half" },
            [_c("UserMeetCard", { attrs: { meet: meet } })],
            1
          )
        }),
        !_vm.meets.length
          ? _c("div", { staticClass: "column has-text-centered" }, [
              _c("p", { staticClass: "subtitle has-text-info" }, [
                _vm._v("You have no meets")
              ])
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }