var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card user-story-card active", on: { click: _vm.goToMeet } },
    [
      _c("div", { staticClass: "card-content" }, [
        _c("div", { staticClass: "media" }, [
          _c("div", { staticClass: "media-left" }, [
            _c("figure", { staticClass: "image thumbnail" }, [
              _c("img", {
                attrs: {
                  src:
                    _vm.meetThumbnail ||
                    "https://dummyimage.com/200x200/162360/32bcac.png&text=No+model+yet",
                  alt: _vm.Meet ? _vm.Meet.title : "No model yet"
                }
              })
            ])
          ]),
          _c(
            "div",
            { staticClass: "media-content" },
            [
              !_vm.loading
                ? _c("p", { staticClass: "title is-5" }, [
                    _vm._v(_vm._s(_vm.Meet.title || _vm.meet.id))
                  ])
                : _vm._e(),
              _c("b-skeleton", { attrs: { active: _vm.loading } }),
              _c(
                "b-field",
                {
                  staticClass: "tag-align",
                  attrs: { grouped: "", "group-multiline": "" }
                },
                [
                  _c(
                    "b-taglist",
                    { attrs: { attached: "" } },
                    [
                      _c("b-tag", { attrs: { type: "is-primary" } }, [
                        _vm._v("Created")
                      ]),
                      _c("b-tag", { attrs: { type: "is-info" } }, [
                        _vm._v(_vm._s(_vm.processDate()))
                      ])
                    ],
                    1
                  ),
                  !_vm.loading
                    ? _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: _vm.Meet.guests.join(", ") || "No guests",
                            type: "is-info",
                            multilined: _vm.Meet.guests.length > 1,
                            position: "is-top",
                            animated: ""
                          }
                        },
                        [
                          _c(
                            "b-taglist",
                            { attrs: { attached: "" } },
                            [
                              _c("b-tag", { attrs: { type: "is-primary" } }, [
                                _vm._v("Guests")
                              ]),
                              _c("b-tag", { attrs: { type: "is-info" } }, [
                                _vm._v(_vm._s(_vm.Meet.guests.length))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            !_vm.loading
              ? _c("p", [_vm._v(_vm._s(_vm.Meet.subtitle))])
              : _vm._e(),
            _c("b-skeleton", { attrs: { active: _vm.loading } })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }