import { render, staticRenderFns } from "./UserDetails.vue?vue&type=template&id=f5e074b4&scoped=true&lang=pug&"
import script from "./UserDetails.vue?vue&type=script&lang=js&"
export * from "./UserDetails.vue?vue&type=script&lang=js&"
import style0 from "./UserDetails.vue?vue&type=style&index=0&id=f5e074b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5e074b4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Apogea\\Projects\\bim-meet\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f5e074b4')) {
      api.createRecord('f5e074b4', component.options)
    } else {
      api.reload('f5e074b4', component.options)
    }
    module.hot.accept("./UserDetails.vue?vue&type=template&id=f5e074b4&scoped=true&lang=pug&", function () {
      api.rerender('f5e074b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UserProfile/UserDetails.vue"
export default component.exports