import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=e928b7d8&scoped=true&lang=pug&"
import script from "./UserProfile.vue?vue&type=script&lang=ts&"
export * from "./UserProfile.vue?vue&type=script&lang=ts&"
import style0 from "./UserProfile.vue?vue&type=style&index=0&id=e928b7d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e928b7d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Apogea\\Projects\\bim-meet\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e928b7d8')) {
      api.createRecord('e928b7d8', component.options)
    } else {
      api.reload('e928b7d8', component.options)
    }
    module.hot.accept("./UserProfile.vue?vue&type=template&id=e928b7d8&scoped=true&lang=pug&", function () {
      api.rerender('e928b7d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/UserProfile.vue"
export default component.exports