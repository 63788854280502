<template lang="pug">
.section.has-background-info.user-info
  .container
    .columns
      .column.is-2.is-offset-2
        img.user-image(:src='$auth.currentUser.photoURL', v-if='$auth.currentUser.photoURL')
        UserAvatar.user-image(:size='170', v-else)
      .column.is-6
        h1.title.is-5.has-text-white.has-text-weight-normal User profile
        BField
          BInput(placeholder='Display name', v-model='userData.displayName')
        BField(:type='$auth.currentUser.emailVerified ? "is-success" : "is-warning"')
          BInput(
            placeholder='This is expanded',
            :value='$auth.currentUser.email',
            expanded,
            disabled
          )
          p.control
            button.button.is-success(v-if='$auth.currentUser.emailVerified', disabled) Email verified
            button.button.is-warning(v-else, @click='sendVerificationMail') Verify email
        BButton(
          type='is-primary',
          @click='updateProfile',
          icon-left='upload',
          :loading='profileUpdating'
        ) Update profile
</template>

<script>
import { sendVerificationMail } from '@/components/auth/emailVerification'
import UserAvatar from '../layout/UserAvatar.vue'
export default {
  name: 'UserDetails',
  props: ['userData'],
  components: { UserAvatar },
  data() {
    return {
      profileUpdating: false
    }
  },
  methods: {
    updateProfile() {
      this.profileUpdating = true
      this.$auth.currentUser
        .updateProfile({
          displayName: this.userData.displayName
        })
        .then(val => {
          let userRef = this.$firestore.doc('users/' + this.$auth.currentUser.uid)
          return userRef.update({
            displayName: this.userData.displayName
          })
        })
        .then(() => {
          this.$buefy.notification.open({
            message: 'Succesfully updated profile',
            type: 'is-success',
            position: 'is-top'
          })
        })
        .catch(err => {
          console.error('Error updating profile', err)
        })
        .finally(() => (this.profileUpdating = false))
    },
    sendVerificationMail() {
      sendVerificationMail(this.$buefy.notification)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%230696d7' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}
.user-image {
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.781);
}
</style>
