var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section has-background-info user-info" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column is-2 is-offset-2" },
          [
            _vm.$auth.currentUser.photoURL
              ? _c("img", {
                  staticClass: "user-image",
                  attrs: { src: _vm.$auth.currentUser.photoURL }
                })
              : _c("UserAvatar", {
                  staticClass: "user-image",
                  attrs: { size: 170 }
                })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column is-6" },
          [
            _c(
              "h1",
              {
                staticClass: "title is-5 has-text-white has-text-weight-normal"
              },
              [_vm._v("User profile")]
            ),
            _c(
              "BField",
              [
                _c("BInput", {
                  attrs: { placeholder: "Display name" },
                  model: {
                    value: _vm.userData.displayName,
                    callback: function($$v) {
                      _vm.$set(_vm.userData, "displayName", $$v)
                    },
                    expression: "userData.displayName"
                  }
                })
              ],
              1
            ),
            _c(
              "BField",
              {
                attrs: {
                  type: _vm.$auth.currentUser.emailVerified
                    ? "is-success"
                    : "is-warning"
                }
              },
              [
                _c("BInput", {
                  attrs: {
                    placeholder: "This is expanded",
                    value: _vm.$auth.currentUser.email,
                    expanded: "",
                    disabled: ""
                  }
                }),
                _c("p", { staticClass: "control" }, [
                  _vm.$auth.currentUser.emailVerified
                    ? _c(
                        "button",
                        {
                          staticClass: "button is-success",
                          attrs: { disabled: "" }
                        },
                        [_vm._v("Email verified")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "button is-warning",
                          on: { click: _vm.sendVerificationMail }
                        },
                        [_vm._v("Verify email")]
                      )
                ])
              ],
              1
            ),
            _c(
              "BButton",
              {
                attrs: {
                  type: "is-primary",
                  "icon-left": "upload",
                  loading: _vm.profileUpdating
                },
                on: { click: _vm.updateProfile }
              },
              [_vm._v("Update profile")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }