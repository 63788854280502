import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import BimIssueViewer from '@/components/autodeskForge/Bim360IssueViewer.vue';
import LoadingMeetModal from '@/components/UserProfile/LoadingMeetModal.vue';
import VerifyEmailTemplate from '@/components/UserProfile/VerifyEmailTemplate.vue';
import UserStories from '@/components/UserProfile/UserStories.vue';
import UserDetails from '@/components/UserProfile/UserDetails.vue';
import EventMeets from '@/components/UserProfile/EventMeets.vue';
import store from '@/store';
var UserProfile = (function (_super) {
    __extends(UserProfile, _super);
    function UserProfile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = true;
        _this.activeTab = 0;
        _this.creatingMeet = false;
        return _this;
    }
    Object.defineProperty(UserProfile.prototype, "userData", {
        get: function () {
            return store.state.user;
        },
        enumerable: false,
        configurable: true
    });
    UserProfile.prototype.createNewMeet = function () {
        var _this = this;
        this.creatingMeet = true;
        var createMeetingFunc = this.$functions.httpsCallable('createMeeting');
        createMeetingFunc()
            .then(function (meetId) {
            console.log('createNewMeet -> meetId', meetId);
            _this.$store.dispatch('loadUserState', { uid: _this.userData.uid });
            _this.$router.push("/meet/" + meetId.data);
        })
            .catch(function (err) {
            var error = err.message;
            _this.showError('User is not active');
        })
            .finally(function () { return (_this.creatingMeet = false); });
    };
    UserProfile.prototype.showError = function (error) {
        this.$buefy.dialog.alert({
            message: "\n        <div class=\"prompt-msg-container\">\n          <p class=\"subtitle\">You must have an active account!</p>\n          <p style=\"color: red;\">*" + error + "</p>\n        </div>\n        "
        });
    };
    Object.defineProperty(UserProfile.prototype, "emailVerified", {
        get: function () {
            return this.$auth.currentUser.emailVerified;
        },
        enumerable: false,
        configurable: true
    });
    UserProfile = __decorate([
        Component({
            components: {
                BimIssueViewer: BimIssueViewer,
                LoadingMeetModal: LoadingMeetModal,
                VerifyEmailTemplate: VerifyEmailTemplate,
                UserStories: UserStories,
                UserDetails: UserDetails,
                EventMeets: EventMeets
            }
        })
    ], UserProfile);
    return UserProfile;
}(Vue));
export default UserProfile;
